import { Segment } from "models/commonModels/ListModels";
import styled from "styled-components";

interface MultiSegmentProgressBarProps {
  segments: Segment[];
  height?: string;
}

interface ProgressBarContainerProps {
  height?: string;
}

interface ProgressSegmentProps {
  width: string;
  color: string;
}

const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  width: 100%;
  height: ${(props) => props.height || "8px"};
  background-color: #e5e7eb;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBarWrapper = styled.div`
  height: 100%;
  display: flex;
`;

const ProgressSegment = styled.div<ProgressSegmentProps>`
  height: 100%;
  width: ${(props) => props.width};
  background-color: ${(props) => {
    const colors: { [key: string]: string } = {
      blue: "#3b82f6",
      orange: "#f97316",
      green: "#22c55e",
    };
    return colors[props.color] || props.color;
  }};
  transition: width 0.3s ease;
`;

const MultiSegmentProgressBar: React.FC<MultiSegmentProgressBarProps> = ({
  segments,
  height,
}) => {
  const total = segments.reduce((sum, segment) => sum + segment.value, 0);

  return (
    <ProgressBarContainer height={height}>
      <ProgressBarWrapper>
        {segments.map((segment, index) => {
          const width = `${(segment.value / total) * 100}%`;
          return (
            <ProgressSegment key={index} width={width} color={segment.color} />
          );
        })}
      </ProgressBarWrapper>
    </ProgressBarContainer>
  );
};

export default MultiSegmentProgressBar;
