import styled, { css } from "styled-components";
import MultiSegmentProgressBar from "../graph/MultiSegmentProgressBar";
import { RegionData, Segment } from "models/commonModels/ListModels";
import DONUT_SYMBOL from "../../../assets/images/donut_symbol.png";

const RegionList = (props: RegionData, key: number) => {
  const { id, name, segments, onOpenDonutGraph, onSelectDonutGraph } = props;

  return (
    <Styles.RegionListContainer
      key={key}
      onClick={() => onSelectDonutGraph && onSelectDonutGraph(id)}
    >
      <Styles.Text
        $styles={{
          width: "28px",
          height: "24px",
          fontSize: "20px",
          fontWeight: "700",
          fontStyle: "italic",
          color: "#202123",
          lineHeight: "24.38px",
          alignSelf: "flex-start",
        }}
      >
        {id}
      </Styles.Text>

      <Styles.FlexColumnLayout $styles={{ gap: "4px", minWidth: "180px" }}>
        <Styles.Text
          $styles={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#202123",
            lineHeight: "21.6px",
          }}
        >
          {name}
        </Styles.Text>

        <MultiSegmentProgressBar segments={segments} height={"12px"} />
      </Styles.FlexColumnLayout>

      <Styles.MobileVisible>
        <Styles.DonutGraphIcon
          src={DONUT_SYMBOL}
          alt="pickus"
          onClick={() => onOpenDonutGraph && onOpenDonutGraph(id)}
        />
      </Styles.MobileVisible>
    </Styles.RegionListContainer>
  );
};

export default RegionList;

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $hoverStyles?: React.CSSProperties | null;
  $borderRightNone?: boolean | null;
  $active?: boolean | null;
}

const Styles = {
  RegionListContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 4px 8px 4px;

    &:hover {
      background: #f3f5f8;
      border-radius: 8px;
    }
  `,

  DonutGraphIcon: styled.img`
    width: 32px;
    height: 32px;
  `,

  Text: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  PcVisible: styled.div<StyleProps>`
    display: block;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<StyleProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  FlexColumnLayout: styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,
};
