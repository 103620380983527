import axios from "axios";

export const fetchSearchResults = async (params) => {
  try {
    const response = await axios.get(
      `https://link.pick-us.co.kr/getsearch?q=${params.q}&num=${params.num}&order=${params.order}&pagetoken=${params.pageToken}&filter=${params.filter}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : "Network error");
  }
};
