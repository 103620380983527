import { Route, Routes, Navigate } from "react-router-dom";
import Main from "pages/main";
import Search from "pages/search";
import Detail from "pages/detail";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/search" element={<Search />} />
      <Route path="/search/view/:id" element={<Detail />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
