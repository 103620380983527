import axios from "axios";

export const fetchDetail = async (id) => {
  try {
    const response = await axios.get(
      `https://link.pick-us.co.kr/getdetail?id=${id}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : "Network error");
  }
};

export const fetchChannelDetail = async (id) => {
  try {
    const response = await axios.get(
      `https://link.pick-us.co.kr/getdetailchannel?id=${id}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : "Network error");
  }
};
