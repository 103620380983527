import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";

interface RollingRankingProps {
  data: Array<{ id: number; title: string }>;
}

const RollingRanking: React.FC<RollingRankingProps> = (props) => {
  const { data } = props;

  const settings = {
    variableWidth: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: true,
    vertical: true,
    autoplay: true,
  };

  const formatRankingNumber = (id: number) => {
    return id.toString().padStart(2, "0");
  };

  return (
    <Styles.Container>
      {data.length > 0 && (
        <Slider {...settings}>
          {data.map((item) => (
            <Styles.RankingList key={item.id}>
              <Styles.RankingNumber>
                {formatRankingNumber(item.id + 1)}
              </Styles.RankingNumber>
              <Styles.RankingValue>{item.title}</Styles.RankingValue>
            </Styles.RankingList>
          ))}
        </Slider>
      )}
    </Styles.Container>
  );
};

export default RollingRanking;

const Styles = {
  Container: styled.div`
    font-family: "Pretendard";
    max-width: max-content;
    margin-top: -1px;

    @media (max-width: ${"768px"}) {
    }
  `,

  RankingList: styled.div`
    display: flex !important;
    align-items: center;
    min-height: 24px;
    gap: 8px;

    @media (max-width: ${"768px"}) {
      gap: 4px;
    }
  `,

  RankingNumber: styled.div`
    width: 26px;
    height: 24px;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 24.38px;
    color: #ff9839;

    @media (max-width: ${"768px"}) {
      font-size: 16px;
    }
  `,

  RankingValue: styled.div`
    height: 19px;
    max-width: 120px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    color: #232020;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: ${"768px"}) {
      max-width: 90px;
      font-size: 14px;
    }
  `,
};
