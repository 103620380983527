const numberFormat = (value: string | number): string => {
  // value가 string이면 숫자로 변환, 숫자라면 그대로 사용
  const numValue = typeof value === "string" ? parseInt(value, 10) : value;

  // 숫자를 'ko-KR' 로케일 형식으로 변환하여 문자열 반환
  const res = numValue.toLocaleString("ko-KR", { maximumFractionDigits: 4 });

  return res;
};

export { numberFormat };
