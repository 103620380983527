import axios from "axios";

export const fetchTrends = async () => {
  try {
    const response = await axios.get(`https://link.pick-us.co.kr/gettrends`);

    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : "Network error");
  }
};
