import Header from "components/Layout/Header";
import SearchPage from "components/Search/SearchPage";

const Search = () => {
  return (
    <div>
      <Header />
      <SearchPage />
    </div>
  );
};

export default Search;
