import { RegionData } from "models/commonModels/ListModels";
import React, { useEffect, useState } from "react";
import { Chart, ChartWrapperOptions } from "react-google-charts";
import styled from "styled-components";

interface DonutChartProps {
  donutChartData?: RegionData | null; // Google Charts options object
}

const DonutChart = (props: DonutChartProps) => {
  const { donutChartData } = props;

  const [data, setData] = useState([
    ["Category", "Percentage"],
    ["뉴진스", 20],
    ["유진스", 45.5],
    ["뉴진스님", 34.5],
  ]);
  const [options, setOptions] = useState({
    title: donutChartData?.name,
    pieHole: 0.6,
    colors: ["#F68445", "#31EB87", "#458BF6"],
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        fontSize: 16,
      },
    },
    chartArea: { width: "392px", height: "200px" },
    pieSliceText: "none",
  });
  const [mobileOptions, setMobileOptions] = useState({
    pieHole: 0.6,
    colors: ["#F68445", "#31EB87", "#458BF6"],
    legend: {
      position: "none",
    },
    chartArea: { width: "90%", height: "70%" },
    pieSliceText: "none",
  });

  useEffect(() => {
    setOptions((prev) => {
      return { ...prev, title: donutChartData?.name };
    });
  }, [donutChartData]);

  // const data = [
  //   ["Category", "Percentage"],
  //   ["뉴진스", 20],
  //   ["유진스", 45.5],
  //   ["뉴진스님", 34.5],
  // ];

  // const options = {
  //   title: donutChartData?.name,
  //   pieHole: 0.6,
  //   colors: ["#F68445", "#31EB87", "#458BF6"],
  //   legend: {
  //     position: "bottom",
  //     alignment: "center",
  //     textStyle: {
  //       fontSize: 12,
  //     },
  //   },
  //   chartArea: { width: "392px", height: "200px" },
  //   pieSliceText: "none",
  // };

  // const mobileOptions = {
  //   pieHole: 0.6,
  //   colors: ["#F68445", "#31EB87", "#458BF6"],
  //   legend: {
  //     position: "none",
  //   },
  //   chartArea: { width: "90%", height: "70%" },
  //   pieSliceText: "none",
  // };

  return (
    <>
      <Styles.PcVisible>
        {donutChartData && (
          <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        )}
      </Styles.PcVisible>

      <Styles.MobileVisible>
        <Chart
          chartType="PieChart"
          width="100%"
          height="300px"
          data={data}
          options={mobileOptions}
        />
        <Styles.Legend>
          {data.slice(1).map(([label, value], index) => (
            <Styles.LegendItem key={index}>
              <Styles.ColorIndicator color={options.colors[index]} />
              <span>{label}</span>
              <Styles.Percentage>{value}%</Styles.Percentage>
            </Styles.LegendItem>
          ))}
        </Styles.Legend>
      </Styles.MobileVisible>
    </>
  );
};

export default DonutChart;

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $borderRightNone?: boolean | null;
  $active?: boolean | null;
}

const Styles = {
  Legend: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  `,

  LegendItem: styled.div`
    display: flex;
    min-width: 140px;
    margin-bottom: 5px;
    font-size: 14px;
  `,

  ColorIndicator: styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    margin-right: 8px;
  `,

  Percentage: styled.span`
    margin-left: auto;
    font-weight: bold;
  `,

  PcVisible: styled.div<StyleProps>`
    display: block;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,
};
