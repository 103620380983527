import styled from "styled-components";

import ARROW_RIGHT from "../../../assets/images/arrow_right_black_24.svg";
import ARROW_LEFT from "../../../assets/images/arrow_left_black_24.svg";
import { useEffect, useState } from "react";

interface KeywordListProps {
  list: Array<{ id: number; value: string }>;
  title: string;
}

const KeywordList = ({ title, list }: KeywordListProps) => {
  const [isDefaultList, setIsDefaultList] = useState(true);

  const handleListSplice = () => {
    setIsDefaultList(!isDefaultList);
  };

  const PrevArrow = () => {
    return (
      <Styles.ArrowButton onClick={() => handleListSplice()}>
        <img src={ARROW_LEFT} alt="pickus" />
      </Styles.ArrowButton>
    );
  };

  const NextArrow = () => {
    return (
      <Styles.ArrowButton onClick={() => handleListSplice()}>
        <img src={ARROW_RIGHT} alt="pickus" />
      </Styles.ArrowButton>
    );
  };

  return (
    <Styles.Container>
      <Styles.TitleSection>
        <Styles.Text
          $styles={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#202123",
            lineHeight: "24px",
          }}
          $mobileStyles={{
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "19.2px",
          }}
        >
          {title}
        </Styles.Text>
        <Styles.ButtonWrap>
          <PrevArrow />
          <NextArrow />
        </Styles.ButtonWrap>
      </Styles.TitleSection>

      {isDefaultList && (
        <Styles.ListWrap>
          <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
            {list.map((item) => {
              if (item.id >= 0 && item.id < 5) {
                return (
                  <Styles.RankingList key={item.id}>
                    <Styles.RankingNumber>{item.id + 1}</Styles.RankingNumber>
                    <Styles.RankingValue>{item.value}</Styles.RankingValue>
                  </Styles.RankingList>
                );
              }
            })}
          </Styles.FlexColumnLayout>
          <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
            {list.map((item) => {
              if (item.id >= 5 && item.id < 10) {
                return (
                  <Styles.RankingList key={item.id}>
                    <Styles.RankingNumber>{item.id + 1}</Styles.RankingNumber>
                    <Styles.RankingValue>{item.value}</Styles.RankingValue>
                  </Styles.RankingList>
                );
              }
            })}
          </Styles.FlexColumnLayout>
        </Styles.ListWrap>
      )}

      {!isDefaultList && (
        <Styles.ListWrap>
          <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
            {list.map((item) => {
              if (item.id >= 10 && item.id < 15) {
                return (
                  <Styles.RankingList key={item.id}>
                    <Styles.RankingNumber>{item.id + 1}</Styles.RankingNumber>
                    <Styles.RankingValue>{item.value}</Styles.RankingValue>
                  </Styles.RankingList>
                );
              }
            })}
          </Styles.FlexColumnLayout>
          <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
            {list.map((item) => {
              if (item.id >= 15 && item.id < 20) {
                return (
                  <Styles.RankingList key={item.id}>
                    <Styles.RankingNumber>{item.id + 1}</Styles.RankingNumber>
                    <Styles.RankingValue>{item.value}</Styles.RankingValue>
                  </Styles.RankingList>
                );
              }
            })}
          </Styles.FlexColumnLayout>
        </Styles.ListWrap>
      )}
    </Styles.Container>
  );
};

export default KeywordList;

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $borderRightNone?: boolean | null;
  $isBorderTopNone?: boolean;
  $isBorderBottomNone?: boolean;
  $active?: boolean;
}

const Styles = {
  Container: styled.div``,

  TitleSection: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  `,

  ListWrap: styled.div`
    display: flex;
    gap: 24px;
  `,

  RankingList: styled.div`
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 24px;
    gap: 12px;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: ${"768px"}) {
      min-width: 130px;
      gap: 4px;
    }
  `,

  RankingNumber: styled.div`
    width: 32px;
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    color: #ff9839;

    @media (max-width: ${"768px"}) {
      width: 24px;
      font-size: 16px;
    }
  `,

  RankingValue: styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #232020;

    @media (max-width: ${"768px"}) {
      font-size: 14px;
    }
  `,

  ButtonWrap: styled.div`
    display: flex;
    gap: 8px;
  `,

  ArrowButton: styled.button`
    width: 40px;
    height: 40px;
    border: 1px solid #e4eaf5;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    img {
      width: 16px;
      height: 16px;
    }

    @media (max-width: ${"768px"}) {
      width: 30px;
      height: 30px;

      img {
        width: 12px;
        height: 12px;
      }
    }
  `,

  Text: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  PcVisible: styled.div<StyleProps>`
    display: block;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<StyleProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  FlexColumnLayout: styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,
};
