import styled from "styled-components";

import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SEARCH from "../../assets/images/search_icon_24.svg";
import LOGO_SMALL from "../../assets/images/logo_small.svg";
import LOGO_MOBILE from "../../assets/images/logo_mobile.svg";
import POPULAR from "../../assets/images/popular_icon.svg";
import ARROW_DOWN from "../../assets/images/arrow_down_24.svg";
import CLOSE_ICON from "../../assets/images/close_icon_black_24.svg";

import RollingRanking from "./components/RollingRanking";
import { fetchTrends } from "services/trendApi";

enum KeyCode {
  Enter = "Enter",
}

enum EventType {
  Click = "click",
  KeyDown = "keydown",
}

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const [trendData, settrendData] = useState(Array(20).fill(""));
  const [searchValue, setSearchValue] = useState("");
  const [isShowKeywords, setIsShowKeywords] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [trendData, setTrendData] = useState<{ id: number; title: string }[]>(
    []
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const handleSearch = (
    e: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLImageElement>
  ): void => {
    if (("key" in e && e.key === KeyCode.Enter) || e.type === EventType.Click) {
      if (searchValue.trim()) {
        const params = new URLSearchParams(location.search);

        params.set("q", encodeURIComponent(searchValue.trim()));

        navigate(`/search?${params.toString()}`, {
          state: { noScroll: true },
        });
      }
    }
  };

  const onShowKeyword = () => {
    setIsShowKeywords(!isShowKeywords);
  };

  const formatRankingNumber = (id: number) => {
    return (id + 1).toString().padStart(2, "0");
  };

  const onClickKeyword = (keyword: string) => {
    navigate(`/search?q=${keyword}`);
  };

  const getTrendData = async () => {
    try {
      const data = await fetchTrends();
      setTrendData(
        data.slice(0, 20).map((item: string, idx: number) => {
          return { id: idx, title: item };
        })
      );
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getTrendData();
  }, []);

  return (
    <Styles.Container>
      <Styles.Header>
        <Styles.FlexRowLayout
          $styles={{ width: "100%", alignItems: "center", gap: "40px" }}
        >
          <Styles.HomeLogo onClick={() => navigate("/")}>
            <Styles.PcVisible>
              <img src={LOGO_SMALL} alt="Logo" />
            </Styles.PcVisible>
            <Styles.MobileVisible>
              <img src={LOGO_MOBILE} alt="Logo" />
            </Styles.MobileVisible>
          </Styles.HomeLogo>

          <Styles.SearchInputWrapper>
            <Styles.FlexRowLayout $styles={{ width: "100%", gap: "8px" }}>
              <Styles.SearchIcon
                src={SEARCH}
                alt="Search"
                onClick={handleSearch}
              />
              <Styles.SearchInput
                type="search"
                value={searchValue}
                onChange={handleSearchChange}
                onKeyDown={handleSearch}
                placeholder="키워드 혹은 영상을 입력해 주세요"
              />
            </Styles.FlexRowLayout>
          </Styles.SearchInputWrapper>
        </Styles.FlexRowLayout>

        <Styles.PopularSection>
          <Styles.PopularBadge>
            <Styles.PopularIcon src={POPULAR} alt="Logo" />
            <Styles.Text
              $styles={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#E85F5F",
                lineHeight: "16.8px",
                textAlign: "center",
              }}
              $mobileStyles={{ fontSize: "12px" }}
            >
              <Styles.PcVisible>인기 검색어</Styles.PcVisible>
              <Styles.MobileVisible>인기</Styles.MobileVisible>
            </Styles.Text>
          </Styles.PopularBadge>

          <Styles.KeywordRollingSection>
            <Styles.RollingBox onClick={() => onShowKeyword()}>
              <RollingRanking data={trendData} />
            </Styles.RollingBox>

            {isShowKeywords && (
              <Styles.KeywordBox>
                <Styles.FlexRowLayout
                  $styles={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0px 0px 12px 0px",
                  }}
                >
                  <Styles.Text
                    $styles={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#202123",
                      lineHeight: "19.2px",
                    }}
                  >
                    인기 검색어
                  </Styles.Text>

                  <Styles.CloseIcon
                    src={CLOSE_ICON}
                    alt="pickus"
                    onClick={() => setIsShowKeywords(false)}
                  />
                </Styles.FlexRowLayout>

                <Styles.RankingTab>
                  <Styles.Tab
                    $active={tabIndex === 0}
                    onClick={() => setTabIndex(0)}
                  >
                    1 ~ 10위
                  </Styles.Tab>
                  <Styles.Tab
                    $active={tabIndex === 1}
                    onClick={() => setTabIndex(1)}
                  >
                    11 ~ 20위
                  </Styles.Tab>
                </Styles.RankingTab>

                <Styles.FlexColumnLayout
                  $styles={{ gap: "12px", margin: "12px 0px 0px 0px" }}
                >
                  {tabIndex === 0 &&
                    trendData.slice(0, 10).map((keyword, index) => (
                      <Styles.RankingItem
                        key={index}
                        onClick={() => navigate(`/search?q=${keyword.title}`)}
                      >
                        <Styles.RankingNumber>
                          {formatRankingNumber(keyword.id)}
                        </Styles.RankingNumber>
                        <Styles.RankingValue
                          onClick={() => onClickKeyword(keyword.title)}
                        >
                          {keyword.title}
                        </Styles.RankingValue>
                      </Styles.RankingItem>
                    ))}

                  {tabIndex === 1 &&
                    trendData.slice(10, 20).map((keyword, index) => (
                      <Styles.RankingItem
                        key={index}
                        onClick={() => navigate(`/search?q=${keyword.title}`)}
                      >
                        <Styles.RankingNumber>
                          {formatRankingNumber(keyword.id)}
                        </Styles.RankingNumber>
                        <Styles.RankingValue
                          onClick={() => onClickKeyword(keyword.title)}
                        >
                          {keyword.title}
                        </Styles.RankingValue>
                      </Styles.RankingItem>
                    ))}
                </Styles.FlexColumnLayout>
              </Styles.KeywordBox>
            )}
          </Styles.KeywordRollingSection>

          <Styles.ArrowIcon
            src={ARROW_DOWN}
            alt="pickus"
            onClick={() => onShowKeyword()}
          />
        </Styles.PopularSection>
      </Styles.Header>
    </Styles.Container>
  );
};

export default Header;

interface ElementProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $active?: boolean;
}

const Styles = {
  Container: styled.div`
    width: 100%;
    border-bottom: 1px solid #e7ebf3;

    @media (max-width: ${"768px"}) {
      border-bottom: none;
    }
  `,

  Header: styled.div`
    font-family: "Pretendard";
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding: 16px 20px 16px 20px;

    @media (max-width: ${"768px"}) {
    }
  `,

  HomeLogo: styled.div`
    cursor: pointer;
  `,

  SearchInputWrapper: styled.div`
    width: 100%;
    max-width: 480px;
    display: flex;
    align-items: center;
    background-color: #eeeff1;
    border-radius: 100px;
    padding: 12px 16px 12px 16px;

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  SearchIcon: styled.img``,

  SearchInput: styled.input`
    width: 100%;
    border: none;
    font-size: 18px;
    font-weight: 500;
    background: transparent;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9b9b9b;
    }
  `,

  PopularIcon: styled.img`
    width: 18px;
    height: 18px;
  `,

  ArrowIcon: styled.img`
    cursor: pointer;
  `,

  CloseIcon: styled.img`
    cursor: pointer;
  `,

  PopularSection: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: ${"768px"}) {
      gap: 8px;
    }
  `,

  PopularBadge: styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 7px 10px 7px 8px;
    background-color: #fff0ea;
    border-radius: 100px;

    @media (max-width: ${"768px"}) {
      padding: 6px 10px 6px 8px;
    }
  `,

  KeywordRollingSection: styled.div``,

  RollingBox: styled.div`
    width: 214px;
    height: 24px;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: ${"768px"}) {
      width: max-content;
    }
  `,

  KeywordBox: styled.div`
    position: absolute;
    width: 300px;
    top: 40px;
    right: 0;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #d2d2d2;
    background-color: #ffffff;
    z-index: 1;
  `,

  RankingTab: styled.div`
    display: flex;
    gap: 4px;
    width: 100%;
    background-color: #f3f5f8;
    border-radius: 6px;
  `,

  Tab: styled.div<ElementProps>`
    flex: 1;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0px 7px 0px;
    text-align: center;
    background-color: ${({ $active }) => ($active ? "#ffffff" : "none")};
    color: ${({ $active }) => ($active ? "#202123" : "#B2B3BC")};
    border-radius: ${({ $active }) => ($active ? "5px" : "none")};
    border: ${({ $active }) => ($active ? "1px solid #E7EBF3" : "none")};
    cursor: pointer;
  `,

  RankingItem: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 0px 0px 8px;
  `,

  RankingNumber: styled.div`
    display: flex;
    align-items: center;
    width: 26px;
    height: 24px;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: 19.5px;
    color: #ff9839;
  `,
  RankingValue: styled.div`
    height: 19px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #202123;
    cursor: pointer;
  `,

  Text: styled.div<ElementProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  PcVisible: styled.div`
    display: block;

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<ElementProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};
  `,

  FlexColumnLayout: styled.div<ElementProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};
  `,
};
