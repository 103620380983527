import DetailPage from "components/Detail/DetailPage";
import Header from "components/Layout/Header";

const Detail = () => {
  return (
    <div>
      <Header />
      <DetailPage />
    </div>
  );
};

export default Detail;
